
import Vue, { PropType } from 'vue'
import Product from '@/components/common/cards/Product.vue'
import { mapGetters, mapActions } from 'vuex'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Product },
  props: {
    /**
     * item : 상품정보
     * onRate : 할인률 show
     * onDiscount : 할인 전 가격 show
     * color : 할인율 색상
     * oneLine : 상품 정보 한줄 표기
     * aspectRatio : 이미지 비율
     * font : 아래 두 줄 참조
     * ->[ 'rixgo-bold', 'rixgo-regular', 'campton-bold','campton-book',]
     * ->['mallName', 'nameKr', 'discountPrice & discountRate' , 'price']
     * fontSizes : 아래 두 줄 참조
     * ->['font-g12-13', font-g12-16, font-g11-12, font-g13-15]
     * ->['mallName', 'nameKr', 'discountPrice & discountRate' , 'price']
     * isMallNameVisible : 몰 이름 표시 여부
     */
    product: {
      type: Object as PropType<ProductItem>,
      default: {} as ProductItem,
    },
    color: {
      type: String,
      default: 'red',
    },
    oneLine: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: 164 / 204,
    },
    onDiscount: {
      type: Boolean,
      default: true,
    },
    onRate: {
      type: Boolean,
      default: true,
    },
    fontSizes: {
      type: Array,
      default: () => [
        'font-g12-18',
        'font-g12-16',
        'font-g13-15',
        'font-g10-18',
      ],
    },
    fonts: {
      type: Array,
      default: () => [
        'rixgo-bold',
        'rixgo-regular',
        'campton-medium',
        'campton-book',
      ],
    },
    productNo: {
      type: String,
      default: () => '',
    },
    // 찜 이미지가 아닌 경우 icon을 받아 lottie 가 아닌 badge로 보여주기
    isShard: {
      type: Boolean,
      default: false,
    },
    // 수정(편집) 여부 - 오른쪽 상단 체크 아이콘 및 이벤트 사용 여부
    onEdit: {
      type: Boolean,
      default: false,
    },
    // 수정(편집) 여부관련 해당 상품이 체크가 되어 있는지에 대한여부
    checked: {
      type: Boolean,
      default: false,
    },
    // 상품 랜딩 페이지 이동 여부
    productRouter: {
      type: Boolean,
      default: true,
    },
    chipContent: {
      type: String,
      default: '',
    },
    imgWrapper: {
      type: String,
      default: '',
    },
    isMallNameVisible: {
      type: Boolean,
      default: true,
    },
    badge: {
      type: Boolean,
      default: true,
    },
    handleProduct: {
      type: Boolean,
      default: false,
    },
    gtagName: {
      type: String,
      default: '',
    },
    useSoldOut: {
      type: Boolean,
      default: false,
    },
    // 상품가격 margin-top이 다른 경우.
    priceSmallPaddingTop: {
      type: Boolean,
      default: false,
    },
    naverDelivery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nameKrHeight: 0,
    }
  },
  created() {
    const textSize = this.fontSizes[1].length
    this.nameKrHeight =
      Number(this.fontSizes[1].slice(textSize - 2)) * (this.oneLine ? 1 : 2)
  },
  computed: {
    handleCheckBox() {
      return this.checked
        ? '$vuetify.icons.dibsCheckOnIcon'
        : '$vuetify.icons.dibsCheckOffIcon'
    },
    ...mapGetters('ExhibitionStore', [
      'getEventExhibitionScrollLocation',
      'getPromotionSingleMallPaging',
      'getPromotionMultiMallPaging',
      'getPromotionMultiMallCarouselInitData',
    ]),
  },
  methods: {
    goPage(path: string) {
      const { mallId, isAvailable, productNo } = this.product

      if (!isAvailable) return
      if (!this.productRouter) return
      if (this.onEdit) return // 찜 편집에서 상품 페이지 랜딩 막기

      switch (path) {
        case 'product':
          if (this.gtagName !== '') this.$tracking(this.gtagName)
          this.$rendingProduct(productNo)
          this.$emit('handleActive', this.product)
          break
        case 'mall':
          if (Object.keys(this.getPromotionSingleMallPaging).length !== 0) {
            localStorage.setItem(
              'promotionSingleMallPaging',
              JSON.stringify(this.getPromotionSingleMallPaging)
            )
          }
          if (
            Object.keys(this.getPromotionMultiMallCarouselInitData).length !== 0
          ) {
            localStorage.setItem(
              'promotionMultiMallCarouselInitData',
              JSON.stringify(this.getPromotionMultiMallCarouselInitData)
            )
          }
          if (Object.keys(this.getPromotionMultiMallPaging).length !== 0) {
            localStorage.setItem(
              'promotionMultiMallPaging',
              JSON.stringify(this.getPromotionMultiMallPaging)
            )
          }
          if (this.getEventExhibitionScrollLocation > 0)
            localStorage.setItem(
              'exhibitionEventScrollLocation',
              JSON.stringify(this.getEventExhibitionScrollLocation)
            )

          this.$emit('onMallClick', mallId)
          if (this.gtagName !== '') this.$tracking(this.gtagName + '_mallname')
          this.$router.push({ name: 'StoreHome', query: { shopId: mallId } })
          break
      }
    },
    isLikeChange(yn: boolean, productNo: string) {
      this.$emit('isLikeChange', yn, productNo)
    },
  },
})

interface Data {
  nameKrHeight: number
}
interface Methods {
  goPage(path: string): void
  isLikeChange(yn: boolean, productNo: string): void
}

interface Computed {
  handleCheckBox: any
  getEventExhibitionScrollLocation: any
  getPromotionSingleMallPaging: any
  getPromotionMultiMallPaging: any
  getPromotionMultiMallCarouselInitData: any
}
interface Props {
  product: ProductItem
  color: string
  oneLine: boolean
  aspectRatio: number
  onDiscount: boolean
  onRate: boolean
  fontSizes: string[]
  fonts: string[]
  productNo: string
  isShard: boolean
  onEdit: boolean
  checked: boolean
  productRouter: boolean
  chipContent: string
  imgWrapper: string
  isMallNameVisible: boolean
  badge: boolean
  handleProduct: boolean
  gtagName: string
  useSoldOut: boolean
  priceSmallPaddingTop: boolean
  naverDelivery: boolean
}
