
import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import { DeleteLikeProduct, PostLikeProduct } from '@/api/displayAPI/UserAPI'
import Lottie from '@/components/common/lottie/LottieView.vue'
import { AnimationItem } from 'lottie-web'
import Sharing from '@/components/common/dialogs/Sharing.vue'
import Alert from '@/components/common/dialogs/Alert.vue'
import { destroyLottieAnim } from '@/plugins/util/lottie'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Product',
  components: { Lottie, Sharing, Alert },
  props: {
    /**
     * Product < Props >
     * image : 카드에 사용할 이미지
     * badge : 배지 사용 여부
     * chip : 칩 사용 여부
     * chipContent : 칩 내용 ( TODO : 이거 하나로 사용해도 될 듯 )
     * badgeX : 배지 가로 컨트롤
     * badgeY : 배지 세로 컨트롤
     * aspectRatio : 이미지 비율
     * imageInInfo : 이미지 안에 정보 노출 여부
     */
    product: {
      type: Object as PropType<ProductItem>,
      default: {} as ProductItem,
    },
    badge: {
      type: Boolean,
      default: false,
    },
    chip: {
      type: Boolean,
      default: false,
    },
    chipContent: {
      type: String,
      default: '',
    },
    badgeX: {
      type: String,
      default: '28',
    },
    badgeY: {
      type: String,
      default: '30',
    },
    blind: {
      type: Boolean,
      default: () => false,
    },
    aspectRatio: {
      type: Number,
      default: () => 165 / 190,
    },
    imageInInfo: {
      type: Boolean,
      default: () => false,
    },
    rate: {
      type: Number,
      default: () => null,
    },
    ratePrice: {
      type: Number,
      default: () => null,
    },
    productNo: {
      type: String,
      default: () => '',
    },
    isShard: {
      type: Boolean,
      default: false,
    },
    imgWrapper: {
      type: String,
      default: '',
    },
    rounded: {
      type: String,
      default: '',
    },
    eventMallType: {
      type: String,
      default: '',
    },
    imageIndex: {
      type: Number,
      default: 0,
    },
    targetImage: {
      type: Number,
      default: 0,
    },
    useSoldOut: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      anim: null,
      show: false,
      isLike: this.product.isLike ?? false,
      url: '',
      alert: {
        show: false,
        message: '',
      },
    }
  },
  computed: {
    ...mapState('PullToRefreshStore', ['refreshNoti']),
    cardStyle() {
      return `width:100%;height:100%;`
    },
  },
  watch: {
    isLogined() {
      this.isLikeChecked()
    },
    refreshNoti() {
      this.isLikeChecked()
    },
    'product.isLike'() {
      this.isLikeChecked()
    },
  },
  mounted() {
    this.playLottie(1)
  },
  beforeDestroy() {
    destroyLottieAnim(this.anim)
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    onImageLoadError(error) {
      console.log('onImageLoadError', error, 'error', this.product)
    },
    onImageLoad() {
      console.log('onImageLoadError', this.product?.shopName)
    },
    isLikeChecked() {
      this.isLike = this.product.isLike ?? false
      this.playLottie(100)
    },
    async likeProduct() {
      try {
        const { productNo, discountPrice, nameKr } = this.product
        this.$kakaoPixel('addToWishList', { id: productNo })
        this.$criteo({
          event: 'addToCart',
          item: [{ id: productNo, price: discountPrice, quantity: '1' }],
        })
        await PostLikeProduct(this.product)
        this.isLike = true
        this.$emit('isLikeChange', this.isLike, this.product.productNo)
      } catch (e: any) {
        if (e && e.error_code === 400) {
          this.alert = { show: true, message: e.message }
        }
      }
    },
    async unlikeProduct() {
      try {
        await DeleteLikeProduct([this.product])
        this.isLike = false
        this.$emit('isLikeChange', this.isLike, this.product.productNo)
      } catch (e) {
        console.log('unlikeProduct error')
      }
    },
    async onChange() {
      if (!this.product.isAvailable) return
      if (!this.isLogined) {
        // 로그인창 오픈
        await this.fetchLoginDialog({ yn: true })
        return
      }
      try {
        this.isLike ? await this.unlikeProduct() : await this.likeProduct()
      } catch (error) {
        console.log(error)
      }

      this.playLottie(1)
    },
    handleAnim(anim: AnimationItem | null) {
      this.anim = anim
    },
    openShare() {
      this.$emit('openShare')
      this.url = `${process.env.VUE_APP_CELLOOK_URL}/?shareItem=${this.product.productNo}`
      this.show = true
    },
    closeShare(type: string) {
      this.show = false
      this.$emit('closeShare', type)
    },
    onClickBlurImage(imageIndex: number) {
      this.$emit('onClickBlurImage', imageIndex)
    },
    playLottie(speed: number) {
      if (!this.anim) return

      if (this.isLike) {
        this.anim.setSpeed(speed)
        this.anim.play()
      } else {
        this.anim.setSpeed(-1 * speed)
        this.anim.play()
      }
    },
  },
})

interface Data {
  anim: AnimationItem | null
  show: boolean
  url: string
  alert: {
    show: boolean
    message: string
  }
  isLike: boolean
}
interface Methods {
  onChange(data: string): void
  handleAnim(anim: AnimationItem | null): void
  fetchLoginDialog({ yn }: { yn: boolean }): void
  likeProduct(): Promise<void>
  unlikeProduct(): void
  openShare(): void
  onClickBlurImage(imageIndex: number): void
  playLottie(spped: number): void
  closeShare(type: string): void
  isLikeChecked(): void
  onImageLoad(): void
  onImageLoadError(error: object | string): void
}
interface Computed {
  cardStyle: string
  refreshNoti: boolean
}
interface Props {
  product: ProductItem
  badge: boolean
  chip: boolean
  chipContent: string
  badgeX: string
  badgeY: string
  blind: boolean
  aspectRatio: number
  imageInInfo: boolean
  rate: number
  ratePrice: number
  productNo: string
  isShard: boolean
  imgWrapper: string
  rounded: string
  eventMallType: string
  imageIndex: number
  targetImage: number
  useSoldOut: boolean
}
