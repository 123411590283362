var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-responsive',{staticClass:"fill-width fill-height relative",attrs:{"aspectRatio":_vm.aspectRatio}},[(_vm.eventMallType === 'single')?[(_vm.imageIndex !== _vm.targetImage)?_c('div',{staticClass:"fill-width fill-height d-flex align-center justify-space-around absolute right-0 z-index-1 bottom-0 rounded",attrs:{"disabled":"true"},on:{"click":function($event){return _vm.onClickBlurImage(_vm.imageIndex)}}}):_vm._e(),_c('div',{staticClass:"fill-width absolute right-0 z-index-1 bottom-0 gradtionBlack",staticStyle:{"height":"70px"}},[_vm._t("store-name"),_c('div',{staticClass:"mx-6 pt-6"},[_c('div',{staticClass:"rixgo-medium white--text font-g12-18 oneLine text-left"},[_vm._v(" "+_vm._s(_vm.product.nameKr)+" ")]),_c('div',{staticClass:"d-flex align-center pt-4"},[_c('div',{staticClass:"d-flex"},[(_vm.product.discountRate > 0)?_c('div',{staticClass:"campton-semibold font-g14-20 red--text ml-auto"},[_vm._v(" "+_vm._s(_vm.product.discountRate)+"% ")]):_vm._e(),_c('div',{class:`campton-semibold font-g14-20 white--text ${
                _vm.product.discountRate > 0 ? 'mx-4' : ''
              }`},[_vm._v(" "+_vm._s(_vm._f("handleTransform")(_vm.product.discountPrice))+" ")]),(_vm.product.discountRate > 0)?_c('div',{staticClass:"campton-book font-g14-20 gray400--text text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm._f("handleTransform")(_vm.product.price))+" ")]):_vm._e()])])])],2)]:_vm._e(),(_vm.eventMallType === 'multi')?[(_vm.imageIndex !== _vm.targetImage)?_c('div',{staticClass:"fill-width fill-height d-flex align-center justify-space-around absolute right-0 z-index-1 bottom-0 rounded",attrs:{"disabled":"true"},on:{"click":function($event){return _vm.onClickBlurImage(_vm.imageIndex)}}}):_vm._e()]:_vm._e(),(_vm.badge)?[_c('v-lazy',[(!_vm.isShard && _vm.product.isAvailable)?_c('Lottie',{staticClass:"absolute right-0 z-index-1 bottom-0",staticStyle:{"width":"38px","height":"38px"},attrs:{"src":require('../../../assets/lottie/dibs.json'),"options":{ autoplay: false, loop: false },"isLike":_vm.isLike},on:{"animCreated":_vm.handleAnim},nativeOn:{"click":function($event){return _vm.onChange()}}}):_vm._e(),(_vm.isShard && _vm.product.isAvailable)?_c('v-icon',{staticClass:"default--text",staticStyle:{"position":"absolute","right":"8px","z-index":"1","bottom":"8px"},nativeOn:{"click":function($event){return _vm.openShare()}}},[_vm._v("$vuetify.icons.badgeExportIcon")]):_vm._e()],1)]:_vm._e(),_c('v-lazy',[_c('v-img',{class:`${_vm.rounded} fill-width fill-height`,attrs:{"src":_vm._f("toSrcPostfix")(_vm._f("toSrcPrefix")(_vm.product.imageUrl1)),"aspectRatio":_vm.aspectRatio,"transition":"cl-img"},on:{"click":function($event){return _vm.$emit('goProduct')},"error":_vm.onImageLoadError,"load":_vm.onImageLoad}},[(!_vm.product.isAvailable)?_c('div',{staticClass:"fill-width fill-height d-flex justify-center align-center gradtionBlack"},[_c('v-img',{staticStyle:{"z-index":"100"},style:(`${
            _vm.eventMallType
              ? 'max-width: 90px'
              : _vm.useSoldOut
              ? 'max-width: 90px'
              : 'max-width: 60px'
          }`),attrs:{"src":require('../../../assets/images/soldOutIcon.svg')}})],1):_vm._e(),(_vm.imgWrapper)?_c('div',{class:`fill-height ${_vm.imgWrapper}`}):_vm._e(),(_vm.chipContent)?_c('div',{staticClass:"font-g8-10 campton-semibold white--text black opacity-7 absolute px-3 py-2",attrs:{"aspect-ratio":_vm.aspectRatio}},[_vm._v(" "+_vm._s(_vm.chipContent)+" ")]):_vm._e(),(_vm.imageInInfo)?_c('div',{staticClass:"absolute bottom-5 left-5"},[(_vm.product.discountRate)?_c('div',{staticClass:"font-g10-11 campton-bold white--text"},[_c('span',[_vm._v(_vm._s(_vm.product.discountRate)+"%")])]):_vm._e(),(_vm.product.discountPrice)?_c('div',{staticClass:"font-g10-11 campton-bold white--text"},[_c('span',[_vm._v(_vm._s(_vm._f("handleTransform")(_vm.product.discountPrice)))])]):_vm._e()]):_vm._e()])],1),_c('Sharing',{attrs:{"sheetTitle":"상품","show":_vm.show,"url":_vm.url,"store":_vm.product.mallName,"content":_vm.product.nameKr,"type":"1","shareItem":{
      title: _vm.product.mallName,
      description: _vm.product.nameKr,
      imageUrl: _vm.product.imageUrl1,
      url: _vm.url,
      appUrl: _vm.product.urlPc ? _vm.product.urlPc : _vm.product.urlMobile,
    },"isProduct":true},on:{"onClose":_vm.closeShare}}),_c('Alert',{attrs:{"show":_vm.alert.show,"content":_vm.alert.message},on:{"onClose":function($event){_vm.alert = { show: false, message: '' }}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }